/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import axios from 'axios';

import paths from './paths';
import { dateDiff } from '../tools/date';

export function getYearlyBreak(date) {
  const yearBreak = new Date(date.getTime());
  yearBreak.setMonth(0, 1);
  yearBreak.setFullYear(date.getFullYear() + 1);
  return yearBreak;
}

export function getYearlyTimespans(start, end) {
  let hasReachedEnd = false;
  let currentStart = start;
  const timespans = [];

  // Iterate from start date towards end
  while (!hasReachedEnd) {
    const currentBreak = getYearlyBreak(currentStart);
    if (dateDiff(currentBreak, end) > 0) {
      // Has reached a break

      // Add segment
      timespans.push({
        start: currentStart,
        end: currentBreak,
      });

      // Iterate
      currentStart = currentBreak;
    } else {
      // Has reached end

      // Add final segment
      timespans.push({
        start: currentStart,
        end,
      });

      // Exit loop
      hasReachedEnd = true;
    }
  }
  console.log(timespans);
  return timespans;
}

export function loadTimeReportOfUser(
  userStartDate,
  beginningOfTomorrow,
  userId,
  addToTotalHoursWorked,
  setHoursThisYearWorked,
) {
  // Iterate through years
  // Clockify Free doesn't support multi-year requests
  const timespans = getYearlyTimespans(userStartDate, beginningOfTomorrow);
  for (let index = 0; index < timespans.length; index += 1) {
    // Add all
    const summarySettings = {
      dateRangeStart: timespans[index].start.toISOString(),
      dateRangeEnd: timespans[index].end.toISOString(), // beginningOfToday.toISOString(),
      summaryFilter: {
        groups: ['USER'],
      },
      users: {
        ids: [userId],
        contains: 'CONTAINS',
        status: 'ALL',
      },
    };
    axios.post(
      `${paths.API_ENDPOINT_REPORTS}/workspaces/${paths.WORKSPACE_ID}/reports/summary`,
      summarySettings,
      paths.AUTH_HEADER,
    ).then((response) => {
      console.log('Time Summary', response);
      const addedTotalHours = response.data.groupOne[0].duration / 60 / 60;
      console.log('added total hours: ', addedTotalHours);
      addToTotalHoursWorked(addedTotalHours);

      if (index + 1 === timespans.length) {
        setHoursThisYearWorked(addedTotalHours);
      }
    }).catch((error) => { console.error(error, summarySettings); });
  }
}

export function loadTimeReportOfTeam(
  fromDate,
  toDate,
  addToTotalHoursWorked,
) {
  // Iterate through years
  // Clockify Free doesn't support multi-year requests
  const timespans = getYearlyTimespans(fromDate, toDate);
  for (let index = 0; index < timespans.length; index += 1) {
    // Add all
    const summarySettings = {
      dateRangeStart: timespans[index].start.toISOString(),
      dateRangeEnd: timespans[index].end.toISOString(), // beginningOfToday.toISOString(),
      summaryFilter: {
        groups: ['USER'],
      },
    };
    axios.post(
      `${paths.API_ENDPOINT_REPORTS}/workspaces/${paths.WORKSPACE_ID}/reports/summary`,
      summarySettings,
      paths.AUTH_HEADER,
    // eslint-disable-next-line no-loop-func
    ).then((response) => {
      console.log('WorkedData', response.data.groupOne);
      addToTotalHoursWorked(response.data.groupOne);
    }).catch((error) => { console.error(error, summarySettings); });
  }
}
