export function formatDuration(duration) {
  return ((Math.round(duration * 10) / 10).toFixed(1)).toString();
}
export function formatDate(date) {
  return new Intl.DateTimeFormat(
    'de-CH',
    {
      month: '2-digit',
      year: '2-digit',
      day: '2-digit',
    },
  )
    .format(date);
}
export function formatWeekDay(date) {
  return new Intl.DateTimeFormat(
    'en-US',
    { weekday: 'long' },
  ).format(date);
}
export function formatLongDate(date) {
  return `${formatDate(date)} - ${formatWeekDay(date)}`;
}
export function formatChange(a, b) {
  const result = a - b;
  return `${result > 0 ? '+' : ''}${formatDuration(result)}`;
}
