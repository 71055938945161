import React from 'react';
import { Route, Switch } from 'react-router-dom';

import './style/App.scss';

import BaseLayout from './components/BaseLayout';
import SignIn from './components/SignIn';
import UserView from './components/UserView';
import AllUserView from './components/AllUsersView';

function App() {
  // Render
  return (
    <div className="App">
      <BaseLayout />
      <Switch>
        <Route
          exact
          path="/"
        >
          <SignIn />
        </Route>
        <Route
          path="/user/:mail"
          render={(renderProps) => (
            <UserView
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...renderProps}
            />
          )}
        />
        <Route
          exact
          path="/overview"
        >
          <AllUserView />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
