import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import useMeasure from 'react-use-measure';

import {
  formatDuration,
  formatLongDate,
} from '../tools/display';
import { getValueFromDate, roundDate } from '../tools/date';

import '../style/Chart.scss';

function revealMainPath(path) {
  const length = path.node().getTotalLength();
  path
    .attr('stroke-dasharray', `${length}, ${length}`)
    .attr('stroke-dashoffset', -length)
    .transition()
    .delay(200)
    .duration(1000)
    .ease(d3.easeQuadOut)
    .attr('stroke-dashoffset', 0);
}
function revealPathToday(path) {
  path
    .attr('opacity', 0)
    .transition()
    .delay(1500)
    .duration(1200)
    .ease(d3.easeQuadOut)
    .attr('opacity', 0.4);
}
function cleanPathAnim(path) {
  path
    .attr('stroke-dasharray', 'none')
    .attr('stroke-dashoffset', 0);
}
function revealMarkerPath(path) {
  const length = path.node().getTotalLength();
  path
    .attr('stroke-dasharray', `${length}, ${length}`)
    .attr('stroke-dashoffset', -length)
    .transition()
    .delay(1200)
    .duration(300)
    .ease(d3.easeQuadOut)
    .attr('stroke-dashoffset', 0);
}
function revealMarkerText(text) {
  text
    .attr('opacity', 0)
    .transition()
    .delay(1200)
    .duration(300)
    .ease(d3.easeQuadOut)
    .attr('opacity', 1);
}
const moveMarkedDate = (path) => {
  path
    .attr('opacity', 0)
    .transition()
    .duration(500)
    .ease(d3.easeQuadOut)
    .attr('opacity', 1);
};
const moveMarkedData = (path) => {
  path
    .attr('opacity', 0)
    .transition()
    .delay(400)
    .duration(500)
    .ease(d3.easeQuadOut)
    .attr('opacity', 1);
};

export default function OvertimeChart({
  data,
  markedDate,
  markDate,
}) {
  // console.log(data);
  const startAnimationRun = useRef(false);

  const interactiveElement = useRef(null);

  const lineElement = useRef(null);
  const lineElementToday = useRef(null);
  const lineGradientElement = useRef(null);

  const areaElement = useRef(null);
  const areaElementDefined = useRef(null);
  const areaGradientElement = useRef(null);

  const markerElement = useRef(null);
  const markerTextElement = useRef(null);
  const markerLineElement = useRef(null);

  const hoverCircleElement = useRef(null);

  const xAxisElement = useRef(null);
  const yAxisElement = useRef(null);

  const [cursorPosition, setCursorPosition] = useState(null);
  const [cursorPressed, setCursorPressed] = useState(false);
  const [cursorInRect, setCursorInRect] = useState(false);

  const heightStretch = 1.5;
  const [chart, bounds] = useMeasure();
  const { width, height } = bounds;

  const margin = ({
    top: 24,
    bottom: 24,
    left: 94,
    right: 94,
  });
  let today;
  let yesterday;
  let yesterdayOvertime;

  const markerHeight = 100;
  const markerTextHeight = markerHeight - 30;
  const markerLineHeight = markerTextHeight - 10;

  const dataTransitionDuration = 400;

  const getScales = () => ({
    xScale: d3
      .scaleUtc()
      .domain(d3.extent(data, (d) => d.date))
      .range([margin.left, width - margin.right]),
    yScale: d3
      .scaleLinear()
      .domain([
        Math.min(
          0,
          d3.min(data, (d) => d.value),
        ),
        Math.max(
          d3.max(data, (d) => d.value),
          -d3.min(data, (d) => d.value),
        )
        * heightStretch,
        // d3.max(data, (d) => d.value) * heightStretch,
      ])
      .range([height - margin.bottom, margin.top]),
  });

  const draw = () => {
    if (
      data
      && data.length > 0
      && width !== 0
      && height !== 0
    ) {
      // console.log('Draw');
      // console.log(bounds);
      // console.log(data);
      // Read values from date
      today = data[0].date;
      yesterday = data[1].date;

      // Scales
      const { xScale, yScale } = getScales();

      // Draw Interaction Capturing
      d3.select(interactiveElement.current)
        .attr('transform', `translate(${margin.left}, ${margin.top})`)
        .attr('height', height - margin.top - margin.bottom)
        .attr('width', width - margin.left - margin.right);

      // Draw Line
      const line = d3
        .line()
        .defined((d) => d.value !== undefined)
        .x((d) => xScale(d.date))
        .y((d) => yScale(d.value));
      if (!startAnimationRun.current) {
        d3.select(lineElement.current)
          .datum(data.filter((d) => d.date < today).filter(line.defined()))
          .attr('d', line)
          .call(revealMainPath);
        d3.select(lineElementToday.current)
          .datum(data.filter((d) => d.date >= yesterday))
          .attr('d', line)
          .call(revealPathToday);
      } else {
        d3.select(lineElement.current)
          .datum(data.filter((d) => d.date < today).filter(line.defined()))
          .transition()
          .duration(dataTransitionDuration)
          .ease(d3.easeQuadOut)
          .attr('d', line)
          .call(cleanPathAnim);
        d3.select(lineElementToday.current)
          .datum(data.filter((d) => d.date >= yesterday))
          .transition()
          .duration(dataTransitionDuration)
          .ease(d3.easeQuadOut)
          .attr('d', line);
      }
      d3.select(lineGradientElement.current)
        .attr('x1', 0)
        .attr('y1', yScale(-1))
        .attr('x2', 0)
        .attr('y2', yScale(1))
        .selectAll('stop')
        .data([
          { offset: '50%', color: '#FC3034ff' },
          { offset: '50%', color: '#ffffffff' },
        ])
        .enter()
        .append('stop')
        .attr('offset', (d) => d.offset)
        .attr('stop-color', (d) => d.color);

      // Draw Area
      const area = d3.area()
        .defined((d) => d.value !== undefined)
        .x((d) => xScale(d.date))
        .y0(yScale(0))
        .y1((d) => yScale(d.value));
      const areaDefined = d3.area()
        .defined((d) => d.value !== undefined && d.required !== 0)
        .x((d) => xScale(d.date))
        .y0(yScale(0))
        .y1((d) => yScale(d.value));
      d3.select(areaElementDefined.current)
        .datum(data.filter((d) => d.date < today))
        .transition()
        .duration(dataTransitionDuration)
        .ease(d3.easeQuadOut)
        .attr('d', areaDefined);
      d3.select(areaElement.current)
        .datum(data.filter((d) => d.date < today).filter(area.defined()))
        .transition()
        .duration(dataTransitionDuration)
        .ease(d3.easeQuadOut)
        .attr('d', area);
      d3.select(areaGradientElement.current)
        .attr('x1', 0)
        .attr('y1', yScale(-1))
        .attr('x2', 0)
        .attr('y2', yScale(1))
        .selectAll('stop')
        .data([
          { offset: '50%', color: '#FC303466' },
          { offset: '50%', color: '#ffffff3D' },
        ])
        .enter()
        .append('stop')
        .attr('offset', (d) => d.offset)
        .attr('stop-color', (d) => d.color);

      // Draw Axes
      const yAxis = d3
        .axisRight(yScale)
        .ticks(3)
        .tickSize(-width + margin.left + margin.right)
        .tickFormat((d) => `${d} h`);
      // d3.select(xAxisElement.current)
      //   .attr('transform', `translate(0,${height - margin.bottom})`)
      //   .call(d3.axisBottom(xScale)
      //     .ticks(d3.timeDay)
      //     .tickFormat((d) => d.toDateString()));
      d3.select(yAxisElement.current)
        .attr('transform', `translate(${width - margin.right},0)`)
        .transition()
        .duration(dataTransitionDuration)
        .ease(d3.easeQuadOut)
        .call(yAxis);
      d3.select(yAxisElement.current)
        .call((g) => g
          .select('.domain')
          .remove())
        .call((g) => g
          .selectAll('.tick text')
          .attr('x', -1)
          .attr('dy', -4))
        .call((g) => g
          .selectAll('.tick line')
          .select(function onlyZero(d) { return d === 0 ? this : null; })
          .attr('class', 'ZeroAxis'))
        .call((g) => g
          .selectAll('.tick text')
          .select(function onlyZero(d) { return d === 0 ? this : null; })
          .attr('class', 'ZeroAxis'));

      // Marker
      if (!startAnimationRun.current) {
        // console.log('DrawStartAnimation');
        d3.select(markerTextElement.current)
          .call(revealMarkerText);
        d3.select(markerLineElement.current)
          .call(revealMarkerPath);
      } else {
        d3.select(markerElement.current)
          .call(moveMarkedData);
      }

      // Run start animation only once
      if (!startAnimationRun.current) {
        startAnimationRun.current = true;
      }
    }
  };

  const drawMarker = () => {
    if (
      data
      && data.length > 0
    ) {
      // Read values from date
      today = data[0].date;
      yesterday = data[1].date;
      yesterdayOvertime = data[1].value;

      // Scales
      const { xScale, yScale } = getScales();

      // Define marker pos
      const markerDate = markedDate === 0
        ? yesterday
        : markedDate;
      const markerValue = markedDate === 0
        ? yesterdayOvertime
        : getValueFromDate(markedDate, data);
      const markedInfo = markedDate === 0
        ? `${formatDuration(yesterdayOvertime)} h`
        : formatLongDate(markerDate);

      // Draw Marker
      const markerLine = d3.path();
      markerLine.moveTo(0, -markerLineHeight);
      markerLine.lineTo(0, 0);
      d3.select(markerElement.current)
        .attr('transform', `translate(${xScale(markerDate)}, ${yScale(markerValue)})`);
      d3.select(markerTextElement.current)
      // .attr("x", 90)
        .attr('y', -markerTextHeight)
        .text(markedInfo)
        .attr('font-size', 10)
        .attr('font-family', 'sans-serif')
        .attr('fill', '#ffffffff')
        .attr('text-anchor', 'middle');
      d3.select(markerLineElement.current)
        .attr('d', markerLine);
    }
  };
  const drawMarkerDate = () => {
    d3.select(markerElement.current)
      .call(moveMarkedDate);
  };

  const drawHoverCircle = () => {
    if (
      cursorPosition !== null
    ) {
      // Scales
      const { xScale, yScale } = getScales();

      // Define marker pos
      const circleDate = roundDate(cursorPosition);
      const circleValue = getValueFromDate(circleDate, data);

      // Draw Marker
      let cursorOpacity = 0.4;
      if (cursorPressed) cursorOpacity = 1.0;
      if (!cursorInRect) cursorOpacity = 0.0;
      d3.select(hoverCircleElement.current)
        .attr('opacity', cursorOpacity)
        .transition()
        .duration(200)
        .ease(d3.easeQuadOut)
        .attr('cx', xScale(circleDate))
        .attr('cy', yScale(circleValue));
      // .attr('transform', `translate(${xScale(circleDate)}, ${yScale(circleValue)})`);
    }
  };

  useEffect(() => draw(), [data, width, height]);
  useEffect(() => drawMarker(), [data, width, height, markedDate]);
  useEffect(() => drawMarkerDate(), [markedDate]);
  useEffect(() => drawHoverCircle(), [cursorPosition, cursorInRect, cursorPressed]);

  // Handlers
  const handleHover = (e) => {
    // console.log(e);
    // console.log(e.nativeEvent.offsetX);
    const { xScale } = getScales();
    const hoveredDate = xScale.invert(e.nativeEvent.offsetX);

    // Move hover circle
    setCursorPosition(hoveredDate);

    // Move marker?
    if (
      cursorInRect
      && cursorPressed
    ) {
      markDate(roundDate(hoveredDate));
    }
  };
  const handleEnterRect = () => {
    setCursorInRect(true);
  };
  const handleLeaveRect = () => {
    setCursorInRect(false);
  };
  const handleMouseDown = (e) => {
    setCursorPressed(true);

    // Move marker?
    if (cursorInRect) {
      const { xScale } = getScales();
      const hoveredDate = xScale.invert(e.nativeEvent.offsetX);
      markDate(roundDate(hoveredDate));
    }
  };
  const handleMouseUp = () => {
    setCursorPressed(false);
  };

  if (
    !data
    || data.length === 0
  ) {
    return (<></>);
  }

  return (
    <svg
      className="OvertimeChart"
      ref={chart}
      viewBox={[0, 0, width, height]}
      pointerEvents="all"
      onMouseMove={(e) => handleHover(e)}
    >
      <path
        className="AreaElement ChartArea Defined"
        ref={areaElementDefined}
      />
      <path
        className="AreaElement ChartArea"
        ref={areaElement}
      />
      <linearGradient
        className="AreaGradient ChartAreaGradient NegativeIsBad"
        ref={areaGradientElement}
        id="area-gradient"
        gradientUnits="userSpaceOnUse"
        fill="#area-gradient"
      />

      <path
        className="LineElementToday ChartLine Prediction"
        ref={lineElementToday}
      />
      <path
        className="LineElement ChartLine"
        ref={lineElement}
      />
      <linearGradient
        className="LineGradient ChartLineGradient NegativeIsBad"
        ref={lineGradientElement}
        id="line-gradient"
        gradientUnits="userSpaceOnUse"
        fill="#line-gradient"
      />

      <circle
        className="HoverCircle"
        ref={hoverCircleElement}
      />

      <g
        className="DateMarker"
        ref={markerElement}
      >
        <text
          className="MarkerText"
          ref={markerTextElement}
          y={-markerTextHeight}
          fontSize={10}
          fill="#ffffffff"
          textAnchor="middle"
        />
        <path
          className="MarkerLine ChartLine"
          ref={markerLineElement}
        />
      </g>

      <g
        className="XAxis ChartAxis"
        ref={xAxisElement}
      />
      <g
        className="YAxis ChartAxis"
        ref={yAxisElement}
      />

      <rect
        className="InteractiveArea"
        ref={interactiveElement}
        onMouseEnter={(e) => handleEnterRect(e)}
        onMouseLeave={(e) => handleLeaveRect(e)}
        onMouseDown={(e) => handleMouseDown(e)}
        onMouseUp={(e) => handleMouseUp(e)}
      />
    </svg>
  );
}
