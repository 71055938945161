/* eslint-disable no-console */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import paths from '../data/paths';
import getCsvData from '../data/csvLoader';
import getRequiredHours from '../data/requiredHours';
import { getDayEntryData, chooseDataSet } from '../data/dayEntries';
import {
  getBeginningOfToday,
  getBeginningOfTomorrow,
  dateDiff,
  parseDate,
} from '../tools/date';
import { loadTimeReportOfUser } from '../data/timeReports';

import TimeList from './TimeList';
import TotalsList from './TotalsList';
import OvertimeChart from './OvertimeChart';
import { formatDate } from '../tools/display';

export default function UserView({ match }) {
  // Who?
  const { mail } = match.params;
  // const [mail, setMail] = useState('');
  const [userId, setUserId] = useState('');
  const [setupError, setError] = useState('');

  // When?
  const beginningOfToday = getBeginningOfToday();
  const beginningOfTomorrow = getBeginningOfTomorrow();
  const [topEntryDate] = useState(beginningOfTomorrow);
  const [userStartDate, setUserStartDate] = useState(0);
  const [userLocation, setUserLocation] = useState('');
  const [userPaidOutHours, setUserPaidOutHours] = useState(0.0);

  // Time data
  const dayEntryCount = 28;
  const [requiredHoursTable, setRequiredHoursTable] = useState({});
  const [requiredHours, setRequiredHours] = useState({ total: 0, entries: [] });

  const [totalHoursWorked, setTotalHoursWorked] = useState(0);
  const [hoursThisYearWorked, setHoursThisYearWorked] = useState(0);
  const addToTotalHoursWorked = (hours) => {
    setTotalHoursWorked((prevHours) => prevHours + hours);
  };

  const [timeEntries, setTimeEntries] = useState([]);
  const [dayEntries, setDayEntries] = useState([]);

  // Interaction data
  const [selectedDataType, selectDataType] = useState('Overtime');
  const [markedDate, markDate] = useState(0);

  const tryMarkDate = (date) => {
    if (dateDiff(markedDate, date) !== 0) {
      markDate(date);
    }
  };

  // Load required hours
  useEffect(() => {
    if (userLocation !== '') {
      getCsvData(`../data/RequiredHours_${userLocation}`)
        .then((response) => {
          // console.log(response.data);
          // console.log(fetch('data/RequiredHours.csv'));
          setRequiredHoursTable(response.data);
        }).catch((error) => { console.error(error); });
    }
  }, [userLocation]);

  // Calculate required hours for user
  useEffect(() => {
    if (
      requiredHoursTable.length > 0
      && userStartDate !== 0
      && setupError === ''
    ) {
      const { requiredHoursTilNow, requiredHoursThisYear } = getRequiredHours(
        requiredHoursTable,
        mail,
        userStartDate,
        topEntryDate,
        userPaidOutHours,
      );
      console.log('Found required hours', requiredHoursTilNow);

      setRequiredHours({ requiredHoursTilNow, requiredHoursThisYear });
    }
  }, [requiredHoursTable, userStartDate, userPaidOutHours]);

  // Load team data
  useEffect(() => {
    if (mail !== '') {
      console.log('Reading Team Data');
      getCsvData('../data/TeamData')
        .then((response) => {
          // console.log('team data loaded: ', response.data);
          // console.log(fetch('data/RequiredHours.csv'));
          let userFound = false;
          let foundStartDate = 0;
          let foundLocation = '';
          let foundPaidOutHours = 0;
          for (let index = 0; index < response.data.length; index += 1) {
            if (response.data[index][0] === mail) {
              // console.log('found data: ', response.data[index][0]);
              userFound = true;
              const StartDate = parseDate(response.data[index][1]);
              foundStartDate = new Date(StartDate);
              const Location = response.data[index][2];
              foundLocation = Location;
              const PaidOutHours = parseFloat(response.data[index][3]);
              foundPaidOutHours = PaidOutHours;
            }
          }
          if (userFound) {
            console.log('Found StartDate', foundStartDate);
            setUserStartDate(foundStartDate);
            console.log('Found Location', foundLocation);
            setUserLocation(foundLocation);
            console.log('Found PaidOutHours', foundPaidOutHours);
            setUserPaidOutHours(foundPaidOutHours);
          } else {
            setError('user not set up');
          }
        }).catch((error) => { console.error(error); });
    } else {
      console.log('No mail set!');
    }
  }, []);

  // Load userData
  useEffect(() => {
    if (mail !== '') {
      axios.get(
        `${paths.API_ENDPOINT}/workspaces/${paths.WORKSPACE_ID}/users?email=${mail}`,
        paths.AUTH_HEADER,
      ).then((response) => {
        if (response.data.length === 0) {
          setError('clockify mail invalid');
        } else {
          console.log('Signed in ', mail, ': ', response);
          setUserId(response.data[0].id);
        }
      }).catch((error) => { console.error(error); });
    }
  }, [mail]);

  // User Loaded?
  useEffect(() => {
    if (
      userId !== ''
      && userStartDate !== 0
    ) {
      if (dateDiff(userStartDate, Date.now()) < 0) {
        setError('not unlocked yet');
      } else {
        // Load Time Report
        loadTimeReportOfUser(
          userStartDate,
          beginningOfTomorrow,
          userId,
          addToTotalHoursWorked,
          setHoursThisYearWorked,
        );

        // Load Time Entries
        const entriesUntil = new Date(topEntryDate);
        entriesUntil.setHours(3, 0, 0, 0);
        // entriesUntil.setDate(entriesUntil.getDate() - 1);
        let entriesFrom = new Date(entriesUntil);
        entriesFrom.setDate(entriesFrom.getDate() - dayEntryCount);
        if (userStartDate > entriesFrom) entriesFrom = userStartDate;
        axios.get(
          `${paths.API_ENDPOINT}/workspaces/${paths.WORKSPACE_ID}/user/${userId}/time-entries?end=${entriesUntil.toISOString()}&start=${entriesFrom.toISOString()}&page-size=500`,
          paths.AUTH_HEADER,
        ).then((response) => {
          console.log('Time Entries:', response);
          // console.log(responseTimeEntries.data[1].timeInterval.duration);
          // console.log(
          //   toSeconds(
          //     parse(
          //       responseTimeEntries.data[1].timeInterval.duration
          //     )
          //   ) / 60 / 60);
          // console.log('time entries are loaded');
          setTimeEntries(response.data);
        }).catch((error) => { console.error(error); });
      }
    }
  }, [userId, topEntryDate, userStartDate]);

  // Date Entries?
  useEffect(() => {
    console.log('data ready? ', timeEntries.length, requiredHours.requiredHoursTilNow, totalHoursWorked);
    if (
      timeEntries.length !== 0
      && requiredHours.requiredHoursTilNow
      && totalHoursWorked >= 0
    ) {
      // console.log('data is ready to be combined into entries');
      const mergedDayEntries = getDayEntryData(
        beginningOfToday,
        timeEntries,
        requiredHours.requiredHoursTilNow,
        totalHoursWorked,
      );
      console.log('Found dayEntries', mergedDayEntries);
      setDayEntries(mergedDayEntries);
    }
  }, [timeEntries, requiredHours.requiredHoursTilNow, totalHoursWorked]);

  // Render
  if (setupError === 'clockify mail invalid') {
    return (
      <div className="NoUser">
        <span>
          No Clockify account was found using this mail address.
        </span>
      </div>
    );
  }
  if (setupError === 'not unlocked yet') {
    return (
      <div className="NoUser">
        <span>
          Your account was set up to start at a later date.
          <br />
          <br />
          {`Please return here on ${formatDate(userStartDate)}.`}
        </span>
      </div>
    );
  }
  if (setupError === 'user not set up') {
    return (
      <div className="NotSetUp">
        <span>
          To use this Service, you need to be activated by SG.
        </span>
      </div>
    );
  }
  if (dayEntries.length === 0) {
    return (
      <div className="Loading" />
    );
  }
  return (
    <div className="App">
      <OvertimeChart
        data={
          chooseDataSet(
            dayEntries,
            selectedDataType,
          )
        }
        markedDate={markedDate}
        markDate={tryMarkDate}
      />
      <TimeList
        dayEntries={dayEntries}
        totalHoursWorked={totalHoursWorked}
        requiredHours={requiredHours.requiredHoursTilNow}
        selectedDataType={selectedDataType}
        selectDataType={selectDataType}
        markedDate={markedDate}
      />
      <TotalsList
        hoursThisYearWorked={hoursThisYearWorked}
        hoursThisYearRequired={requiredHours.requiredHoursThisYear}
      />
    </div>
  );
}
