import React from 'react';

// import Header from './Header';

import '../style/BaseLayout.scss';

export default function BaseLayout() {
  return (
    <div className="BaseLayout">
      {/* <Header
        links={[
          {
            label: 'Observation Desk',
            href: 'https://halterag.sharepoint.com/sites/RaumgleiterObservationDesk',
          },
          {
            label: 'Knowledge Base',
            href: 'https://knowledge.raumgleiter.com',
          },
        ]}
      /> */}
      <div className="Title">
        <h1>Your Overtime</h1>
        <span>
          Track your time now in
          {' '}
          <a href="https://knowledge.raumgleiter.com/docs/knowledge-base/raumgleiter/firma/timetracking-tipps/">
            Clockify
          </a>
          .
        </span>
      </div>
    </div>
  );
}
