import { readString } from 'react-papaparse';

async function fetchCsv(fileName) {
  const response = await fetch(`${fileName}.csv`);
  const reader = response.body.getReader();
  const result = await reader.read();
  const decoder = new TextDecoder('utf-8');
  const csv = await decoder.decode(result.value);
  // console.log('csv', csv);
  return csv;
}

async function getCsvData(fileName) {
  // console.log(fileName);
  const data = readString(await fetchCsv(fileName));
  // console.log(data);
  return data;
}
export default getCsvData;

export function convertArrayOfObjectsToCSV(
  data,
  columnDelimiter = ',',
  lineDelimiter = '\n',
) {
  let result;
  let ctr;
  if (data == null || !data.length) {
    console.log('Data is empty!');
    return null;
  }

  const keys = Object.keys(data[0]);

  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  data.forEach((item) => {
    ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];
      ctr += 1;
    });
    result += lineDelimiter;
  });

  // console.log('Downloaded csv:', result);
  return result;
}

export function getCsvDownloadLink(
  data,
  columnDelimiter = ',',
  lineDelimiter = '\n',
  // filename = 'export.csv',
) {
  let csv = convertArrayOfObjectsToCSV(
    data,
    columnDelimiter,
    lineDelimiter,
  );
  if (csv == null) {
    console.log('Failed to create csv');
    return '';
  }

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }
  return encodeURI(csv);

  // const link = document.createElement('a');
  // link.setAttribute('href', data);
  // link.setAttribute('download', filename);
  // link.click();
}
