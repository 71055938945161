/* eslint-disable no-unused-vars */
import { getBeginningOfToday, parseDate } from '../tools/date';

export default function getRequiredHours(
  requiredHoursTable,
  mail,
  fromDate,
  toDate,
  userPaidOutHours = 0,
) {
  // Add dates of this year
  const yearStart = getBeginningOfToday();
  yearStart.setMonth(0, 1);
  const yearEnd = getBeginningOfToday();
  yearEnd.setFullYear(yearEnd.getFullYear() + 1, 0, 1);

  // Look for columns
  let percentageColumn = -1;
  for (let iX = 0; iX < requiredHoursTable[0].length; iX += 1) {
    if (requiredHoursTable[0][iX] === mail) {
      // console.log('Found work requirements for user ', mail);
      percentageColumn = iX;
      break;
    }
  }
  const dateColumn = 0;
  const durationColumn = 1;

  // Prepare Data
  const requiredHoursTilNow = {
    total: 0,
    entries: [],
  };
  let requiredHoursThisYear = 0;
  let requiredDate;
  let requiredDuration;
  let requiredPercentage;
  let resultingHours;
  // console.log(toDate);
  // console.log(requiredHoursTable);

  // Gather Data
  for (let iY = 1; iY < requiredHoursTable.length; iY += 1) {
    requiredDate = parseDate(requiredHoursTable[iY][dateColumn]);
    requiredDuration = requiredHoursTable[iY][durationColumn];
    requiredPercentage = percentageColumn >= 0 ? requiredHoursTable[iY][percentageColumn] : 100;
    // console.log(requiredDate);
    // console.log(
    //   userStartDate,
    //   requiredDate === 0 ? 'not recognized' : requiredDate, toDate);
    if (
      requiredDate !== 0
      && requiredDuration > 0
      && requiredPercentage > 0
    ) {
      // Date in range?
      if (
        requiredDate >= fromDate
          && requiredDate < toDate
      ) {
        resultingHours = requiredDuration * 0.01 * requiredPercentage;
        requiredHoursTilNow.total += resultingHours;
        requiredHoursTilNow.entries.push({
          date: requiredDate,
          hours: resultingHours,
        });
      }
      // This year?
      if (requiredDate >= yearStart && requiredDate < yearEnd) {
        requiredHoursThisYear += requiredDuration * 0.01 * requiredPercentage;
      }
    }
  }

  // Remove hours already paid out
  requiredHoursTilNow.total += userPaidOutHours;

  return { requiredHoursTilNow, requiredHoursThisYear };
}
