const paths = {
  API_ENDPOINT: 'https://api.clockify.me/api/v1',
  API_ENDPOINT_REPORTS: 'https://reports.api.clockify.me/v1',
  WORKSPACE_ID: '5fd9dcd195b4d303dbfcf163',
  API_KEY: 'X/gvt1W6iTzZv+TL',
  AUTH_HEADER: {
    headers: {
      'X-Api-Key': 'X/gvt1W6iTzZv+TL',
    },
  },
};
export default paths;
