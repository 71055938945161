import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import '../style/SignIn.scss';

export default function SignIn() {
  const [mail, setMail] = useState('');
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push(`/user/${mail}`);
  };

  return (
    <form
      className="SignIn"
      onSubmit={handleSubmit}
    >
      <input
        placeholder="enter your clockify mail"
        type="email"
        name="email"
        required
        autoComplete="on"
        value={mail}
        onChange={(e) => setMail(e.target.value)}
      />
      <input
        type="submit"
        value="sign in"
      />

    </form>
  );
}
