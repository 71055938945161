import { parse, toSeconds } from 'iso8601-duration';

import { dateDiff } from '../tools/date';

function durationToHours(duration) {
  // console.log(duration);
  if (duration === null) {
    return 0;
  }
  return toSeconds(
    parse(
      duration,
    ),
  ) / 60 / 60;
}

export function getDayEntryData(
  latestDate,
  timeEntries,
  requiredHours,
  totalHoursWorked,
) {
  // console.log(timeEntries);
  const dayEntries = [];
  let earliestEntry;

  // Merge time entries to dates for complete days
  let checkedEntryDate;
  let checkedEntryDuration;
  for (let iNew = timeEntries.length - 1; iNew >= 0; iNew -= 1) {
    checkedEntryDate = new Date(timeEntries[iNew].timeInterval.start);
    checkedEntryDate.setHours(3, 0, 0, 0);
    checkedEntryDuration = durationToHours(timeEntries[iNew].timeInterval.duration);
    // Add time to entry
    let wasAdded = false;
    for (let iListed = 0; iListed < dayEntries.length; iListed += 1) {
      // console.log(dayEntries[iListed].date, checkedEntryDate);
      if (dayEntries[iListed].date.getDate() === checkedEntryDate.getDate()) {
        // console.log('found match');
        dayEntries[iListed].worked += checkedEntryDuration;
        wasAdded = true;
        break;
      }
    }
    // Add entry
    if (!wasAdded) {
      dayEntries.push({
        date: checkedEntryDate,
        worked: checkedEntryDuration,
        required: 0,
        change: 0,
        totalOvertime: undefined,
      });

      if (
        !earliestEntry
        || checkedEntryDate.getDate() < earliestEntry.getDate()
      ) {
        earliestEntry = checkedEntryDate;
      }
    }
  }

  // Sort
  dayEntries.sort((a, b) => (a.date < b.date ? 1 : -1));

  // Add missing dates
  const earliestDate = dayEntries[dayEntries.length - 1].date;
  // const latestDate = dayEntries[0].date;
  const checkedDate = new Date(earliestDate);
  let iDate = dayEntries.length - 1;
  // console.log('earliestDate', earliestDate);
  // console.log('checkedDate', checkedDate);
  // console.log('latestDate', latestDate);
  while (dateDiff(checkedDate, latestDate) >= 0) {
    // Already exists?
    // if (iDate > 0) {
    //   console.log(
    //     'checked: ',
    //     checkedDate,
    //     ' entry: ',
    //     dayEntries[iDate],
    //     ' diff: ',
    //     dateDiff(checkedDate, dayEntries[iDate].date),
    //   );
    // }
    if (iDate >= 0 && dateDiff(checkedDate, dayEntries[iDate].date) === 0) {
      // The date exists, no need for action, go on!
      checkedDate.setHours(27);
      iDate -= 1;
    } else {
      // The date must be missing, add it and continue with the next date
      // console.log('date missing on: ', checkedDate);
      dayEntries.splice(
        iDate >= 0 ? iDate + 1 : 0,
        0,
        {
          date: new Date(checkedDate),
          worked: 0,
          required: 0,
          change: 0,
          totalOvertime: undefined,
        },
      );
      // console.log('added ', checkedDate);
      checkedDate.setHours(27);
    }
    // console.log(checkedDate);
  }

  // Add required hours
  // console.log('dayEntries', dayEntries);
  // console.log('requiredHours', requiredHours);
  for (let iListed = 0; iListed < dayEntries.length; iListed += 1) {
    dayEntries[iListed].required = 0;
    // for (let iNew = 0; iNew < requiredHours.entries.length; iNew += 1) {
    for (let iNew = requiredHours.entries.length - 1; iNew >= 0; iNew -= 1) {
      // console.log('check performed, optimize?');
      // console.log(dayEntries[iListed].date, requiredHours.entries[iNew].date);
      if (dateDiff(dayEntries[iListed].date, requiredHours.entries[iNew].date) === 0) {
        dayEntries[iListed].required = requiredHours.entries[iNew].hours;
        break;
      }
    }
  }
  // console.log('dayEntries with workload', dayEntries);

  // Calculate change for each day
  for (let index = 0; index < dayEntries.length; index += 1) {
    dayEntries[index].change = dayEntries[index].worked - dayEntries[index].required;
  }

  // Calculate total overtime for each day, starting from the latest day
  dayEntries[0].totalOvertime = totalHoursWorked - requiredHours.total;
  for (let index = 1; index < dayEntries.length; index += 1) {
    dayEntries[index].totalOvertime = dayEntries[index - 1].totalOvertime
      - dayEntries[index - 1].change;
  }

  // Fill in missing overtime
  for (let i = 0; i < dayEntries.length; i += 1) {
    if (dayEntries[i].totalOvertime === undefined) {
      // console.log('missing', dayEntries[i]);
      for (let iLast = i + 1; iLast < dayEntries.length; iLast += 1) {
        // console.log('checking', dayEntries[iLast]);
        if (dayEntries[iLast].totalOvertime !== undefined) {
          // console.log('found', dayEntries[iLast]);
          dayEntries[i].totalOvertime = dayEntries[iLast].totalOvertime;
          break;
        }
      }
    }
  }

  // eslint-disable-next-line no-console
  // console.log('dayEntries: ', dayEntries);
  return dayEntries;
}

export function chooseDataSet(
  data,
  chosenType,
) {
  const returnedData = [];

  let chosenTypeString;
  switch (chosenType) {
    case 'Worked':
      chosenTypeString = 'worked';
      break;
    case 'Required':
      chosenTypeString = 'required';
      break;
    case 'Change':
      chosenTypeString = 'change';
      break;
    case 'Overtime':
    default:
      chosenTypeString = 'totalOvertime';
      break;
  }
  for (let i = 0; i < data.length; i += 1) {
    returnedData.push({
      date: data[i].date,
      value: data[i][chosenTypeString],
      required: data[i].required,
    });
  }
  return returnedData;
}
