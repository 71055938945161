/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import paths from '../data/paths';
import getCsvData, { getCsvDownloadLink } from '../data/csvLoader';
import {
  getStartOfTracking,
  getBeginningOfToday,
  getBeginningOfTomorrow,
  getStartOfWeek,
  dateDiff,
  parseDate,
} from '../tools/date';
import getRequiredHours from '../data/requiredHours';
import { formatDate, formatDuration } from '../tools/display';
import { loadTimeReportOfTeam } from '../data/timeReports';

import '../style/AllUsersView.scss';

export default function AllUsersView() {
  const fromDate = getStartOfTracking();
  // const toDate = getBeginningOfToday();
  // const toDate = getBeginningOfTomorrow();
  const toDate = getStartOfWeek();

  const [workedData, setWorkedData] = useState([]);
  const addToTotalHoursWorked = (newData) => {
    // console.log(newData);
    setWorkedData((prevData) => {
      // console.log(prevData);
      if (prevData.length === 0) {
        // console.log(newData);
        return newData;
      }
      const summedData = prevData.map((item, index) => {
        // Find data of new data
        // eslint-disable-next-line no-underscore-dangle
        const newItem = newData.find((value) => value._id === item._id);
        if (!newItem) {
          return item;
        }

        // Add and return time worked
        return {
          ...item,
          duration: item.duration + newItem.duration,
        };
      });
      // console.log(summedData);
      return summedData;
    });
  };

  const [userData, setUserData] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [requiredZhData, setRequiredZhData] = useState([]);
  const [requiredHuData, setRequiredHuData] = useState([]);
  const [compiledData, setCompiledData] = useState([]);

  useEffect(
    () => {
      loadTimeReportOfTeam(
        fromDate,
        toDate,
        addToTotalHoursWorked,
      );
      // console.log('hui');

      // Load Users
      axios.get(
        `${paths.API_ENDPOINT}/workspaces/${paths.WORKSPACE_ID}/users`,
        paths.AUTH_HEADER,
      ).then((response) => {
        console.log('UserData', response.data);
        setUserData(response.data);
      }).catch((error) => { console.error(error); });
      // console.log('hui');

      // Load TeamData
      getCsvData('./data/TeamData')
        .then((response) => {
          setTeamData(response.data);
          console.log('TeamData', response.data);
        }).catch((error) => { console.error(error); });

      // Load required hours
      const teamTableInfo = [
        {
          userLocation: 'ZH',
          setter: setRequiredZhData,
        },
        {
          userLocation: 'HU',
          setter: setRequiredHuData,
        },
      ];
      for (let i = 0; i < teamTableInfo.length; i += 1) {
        getCsvData(`./data/RequiredHours_${teamTableInfo[i].userLocation}`)
          .then((response) => {
            console.log('RequiredHours', teamTableInfo[i].userLocation, response.data);
            // console.log(fetch('data/RequiredHours.csv'));
            teamTableInfo[i].setter(response.data);
          }).catch((error) => { console.error(error); });
      }
    },
    [],
  );

  useEffect(
    () => {
      if (
        userData.length > 0
        && workedData.length > 0
        && teamData.length > 0
        && requiredZhData.length > 0
        && requiredHuData.length > 0
      ) {
        console.log('Data complete');
      }
      console.log(
        userData.length,
        workedData.length,
        teamData.length,
        requiredZhData.length,
        requiredHuData.length,
      );

      // Go through users and find relevant data
      const relevantUserData = [];
      for (let iUser = 0; iUser < userData.length; iUser += 1) {
        // console.log(userData[iUser].email);
        for (let iTeam = 0; iTeam < teamData.length; iTeam += 1) {
          if (userData[iUser].email === teamData[iTeam][0]) {
            // Found the data relevant to this user
            const foundUserData = {
              id: userData[iUser].id,
              name: userData[iUser].name,
              mail: userData[iUser].email,
              team: teamData[iTeam][2],
              startDate: parseDate(teamData[iTeam][1]),
              hoursWorked: undefined,
              hoursRequired: undefined,
              totalOvertime: undefined,
              paidOutHours: parseFloat(teamData[iTeam][3]),
            };

            // Add worked hours
            // console.log(workedData);
            // eslint-disable-next-line no-underscore-dangle
            const clockifyData = workedData.find((data) => data._id === foundUserData.id);
            if (clockifyData) {
              foundUserData.hoursWorked = clockifyData.duration / 60 / 60;
              if (clockifyData.name === 'Simon Gloor') {
                // console.log(clockifyData);
                // console.log(fromDate);
              }
            }

            // Add required hours
            const requiredHours = getRequiredHours(
              foundUserData.team === 'ZH' ? requiredZhData : requiredHuData,
              foundUserData.mail,
              foundUserData.startDate,
              toDate,
              foundUserData.paidOutHours,
            );
            foundUserData.hoursRequired = requiredHours.total;

            // Add total overtime
            foundUserData.totalOvertime = foundUserData.hoursWorked - foundUserData.hoursRequired;

            // Add entry to dataset
            relevantUserData.push(foundUserData);
          }
        }
      }
      console.log('Compiled Data:', relevantUserData);
      setCompiledData(relevantUserData);
    },
    [
      userData,
      workedData,
      requiredZhData,
      requiredHuData,
    ],
  );

  const items = [];
  for (let i = 0; i < compiledData.length; i += 1) {
    items.push(
      <tr
        key={compiledData[i].id}
        className={`${!compiledData[i].totalOvertime || compiledData[i].totalOvertime < 0 ? 'Negative' : ''}`}
      >
        <th>{compiledData[i].name}</th>
        <th>{compiledData[i].mail}</th>
        <th>{compiledData[i].team}</th>
        <th>{formatDate(compiledData[i].startDate)}</th>
        <th>{formatDuration(compiledData[i].hoursRequired)}</th>
        <th>{formatDuration(compiledData[i].hoursWorked)}</th>
        <th>{formatDuration(compiledData[i].totalOvertime)}</th>
      </tr>,
    );
  }

  return (
    <div
      className="AllUsersView"
    >
      <a
        className="RGButton"
        download={`OvertimeOverview-${new Date().toISOString()}.csv`}
        href={getCsvDownloadLink(
          compiledData,
          ';',
        )}
      >
        download csv
      </a>
      <table>
        <tbody>
          <tr>
            <th>Name</th>
            <th>Mail</th>
            <th>Team</th>
            <th>Start Date</th>
            <th>Required</th>
            <th>Worked</th>
            <th>Overtime</th>
          </tr>
          {items}
        </tbody>
      </table>
    </div>
  );
}
