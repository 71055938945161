import React from 'react';

import {
  formatDate,
  formatWeekDay,
  formatDuration,
  formatChange,
} from '../tools/display';
import { dateDiff, getDataFromDate } from '../tools/date';

import '../style/TimeList.scss';

export default function TimeList({
  dayEntries,
  requiredHours,
  totalHoursWorked,
  selectedDataType,
  selectDataType,
  markedDate,
}) {
  // Not Ready Yet?
  if (
    dayEntries.length === 0
    || requiredHours.total === 0
    || totalHoursWorked === 0
  ) {
    // console.log('not ready', dayEntries.length, requiredHours.total, totalHoursWorked);
    return <></>;
  }
  console.log({ dayEntries, requiredHours, totalHoursWorked });

  // const renderTopEntryDate = () => {
  //   const displayedDate = new Date(date);
  //   displayedDate.setDate(displayedDate.getDate() - 1);
  //   return formatDate(displayedDate);
  // };

  // Select data
  const today = dayEntries[0].date;
  const renderedData = markedDate === 0
    ? dayEntries[1]
    : getDataFromDate(markedDate, dayEntries);

  // Handlers
  const handleClick = (type) => {
    // console.log(type);
    selectDataType(type);
  };

  // Render
  const titleRowClass = renderedData.totalOvertime < 0 ? 'Negative' : '';
  return (
    <div className="TimeList">
      <div className="Date">
        <h3
          className={titleRowClass}
        >
          {
            dateDiff(renderedData.date, today) > 1
              ? formatDate(renderedData.date)
              : formatWeekDay(renderedData.date)
          }
        </h3>
        <span className={`Label ${titleRowClass}`}>
          Date
        </span>
      </div>
      <div
        className={`Required Selectable ${selectedDataType === 'Required' ? 'IsSelected' : ''}`}
        onClick={() => handleClick('Required')}
        onKeyDown={() => handleClick('Required')}
        role="button"
        tabIndex={-1}
      >
        <h3
          className={titleRowClass}
        >
          {`${formatDuration(renderedData.required)}`}
        </h3>
        <span className={`Label ${titleRowClass}`}>
          Required
        </span>
      </div>
      <div
        className={`Worked Selectable ${selectedDataType === 'Worked' ? 'IsSelected' : ''}`}
        onClick={() => handleClick('Worked')}
        onKeyDown={() => handleClick('Worked')}
        role="button"
        tabIndex={-1}
      >
        <h3
          className={titleRowClass}
        >
          {`${formatDuration(renderedData.worked)}`}
        </h3>
        <span className={`Label ${titleRowClass}`}>
          Worked
        </span>
      </div>
      <div
        className={`Change Selectable ${selectedDataType === 'Change' ? 'IsSelected' : ''}`}
        onClick={() => handleClick('Change')}
        onKeyDown={() => handleClick('Change')}
        role="button"
        tabIndex={-1}
      >
        <h3
          className={titleRowClass}
        >
          {`${formatChange(renderedData.worked, renderedData.required)}`}
        </h3>
        <span className={`Label ${titleRowClass}`}>
          Overtime
        </span>
      </div>
      <div
        className={`Overtime Selectable ${selectedDataType === 'Overtime' ? 'IsSelected' : ''}`}
        onClick={() => handleClick('Overtime')}
        onKeyDown={() => handleClick('Overtime')}
        role="button"
        tabIndex={-1}
      >
        <h3
          className={titleRowClass}
        >
          {`${formatDuration(renderedData.totalOvertime)} h`}
        </h3>
        <span
          className={`Label ${titleRowClass}`}
        >
          Total Overtime
        </span>
      </div>
    </div>
  );
}
