import React from 'react';

import {
  formatDuration,
} from '../tools/display';

import '../style/TimeList.scss';

export default function TimeList({
  hoursThisYearWorked,
  hoursThisYearRequired,
}) {
  // Not Ready Yet?
  if (
    hoursThisYearRequired === 0
  ) {
    // console.log('not ready', dayEntries.length, requiredHours.total, totalHoursWorked);
    return <></>;
  }

  // Render
  return (
    <div className="TotalsList">
      <div className="Progress">
        <div
          className="ProgressBar"
          style={{ width: `${hoursThisYearWorked / hoursThisYearRequired / 0.01}%` }}
        />
      </div>
      <div className="TimeList">
        <div
          className="Worked IsSelected"
          role="button"
          tabIndex={-1}
        >
          <h3>
            {`${formatDuration(hoursThisYearWorked)}`}
          </h3>
          <span className="Label">
            Total Worked
          </span>
        </div>
        <div
          className="Change NotSelected"
          role="button"
          tabIndex={-1}
        >
          <h3>
            {`${formatDuration(hoursThisYearRequired - hoursThisYearWorked)}`}
          </h3>
          <span className="Label">
            Left this Year
          </span>
        </div>
        <div
          className="Change NotSelected"
          role="button"
          tabIndex={-1}
        >
          <h3>
            {`${formatDuration(hoursThisYearRequired)}`}
          </h3>
          <span className="Label">
            Reqired this Year
          </span>
        </div>
      </div>
    </div>
  );
}
