export function getBeginningOfToday() {
  const beginningOfToday = new Date();
  beginningOfToday.setHours(3, 0, 0, 0);
  return beginningOfToday;
}

export function getBeginningOfTomorrow() {
  const beginningOfTomorrow = new Date();
  beginningOfTomorrow.setHours(27, 0, 0, 0);
  return beginningOfTomorrow;
}

export function getStartOfWeek() {
  // Get current date
  const curr = new Date();

  // First day is the day of the month - the day of the week
  const first = curr.getDate() - curr.getDay();
  const firstday = new Date(curr.setDate(first));
  firstday.setHours(3, 0, 0, 0);

  return firstday;
}

export function getStartOfTracking() {
  return new Date(2021, 0, 1, 3, 0, 0, 0);
}

export function parseDate(dateString) {
  if (dateString === '') {
    return 0;
  }
  const parsedDate = new Date(
    `${dateString.substr(6, 4)}-${dateString.substr(3, 2)}-${dateString.substr(0, 2)}`,
  );
  parsedDate.setHours(3, 0, 0, 0);
  return parsedDate;
}

export function dateDiff(first, second) {
  // Take the difference between the dates and divide by milliseconds per day.
  // Round to nearest whole number to deal with DST.
  return Math.round((second - first) / (1000 * 60 * 60 * 24));
}

export function roundDate(date) {
  const hours = date.getHours();
  const returnedValue = date;
  if (hours < (27 + 3) / 2) {
    returnedValue.setHours(3, 0, 0, 0);
  } else {
    returnedValue.setHours(27, 0, 0, 0);
  }
  // console.log(returnedValue);
  // console.log(returnedValue.toString());
  return returnedValue;
}

export function getDataFromDate(date, data) {
  const result = data.find((item) => dateDiff(item.date, date) === 0);
  if (result) return result;
  return data[0];
}

export function getValueFromDate(date, data) {
  return getDataFromDate(date, data).value;
}
